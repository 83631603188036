<template>
  <div class="settings">
    <SwitchButtons
      v-model="tab"
      col-class="col-auto p-0 m-0"
      btn-class="btn-tab"
      row-class="row row-tabs"
      btn-class-active="active"
      btn-class-default=""
      :buttons="selectedTabs"
    />
    <keep-alive>
      <component :is="currentTabComponent" v-model:tab="tab"></component>
    </keep-alive>
  </div>
</template>

<script>
import SwitchButtons from "../../components/inputs/SwitchButtons";
import { defineAsyncComponent } from "vue";

export default {
  name: "Settings",
  data() {
    return {
      tabs: [
        [{ name: "Account", value: "Account" }],
        [{ name: "Billing", value: "Billing" }],
        [
          { name: "General", value: "General" },
          { name: "Line options", value: "LineOptions" },
          { name: "CRM", value: "Crm" },
          { name: "Products", value: "Products" },
        ],
        [{ name: "Users", value: "Users" }],
        [
          { name: "Companies", value: "Companies" },
          { name: "Warehouses", value: "Warehouses" },
        ],
        [
          { name: "Events", value: "Events" },
          { name: "Sale channels", value: "webshops" },
          { name: "Servers", value: "Servers" },
          { name: "Domains", value: "Domains" },
          { name: "Countries", value: "Countries" },
          { name: "Return & change", value: "Returns" },
        ],
        [{ name: "Integrations", value: "integrations" }],
      ],
      currentTabComponent: defineAsyncComponent(() =>
        import(`../../components/settings/Start.vue`)
      ),
      tab: "Start",
      newPassword: "",
    };
  },
  computed: {
    selectedTabs() {
      let tabs = this.tabs.find(
        (e) => e.findIndex((f) => f.value === this.tab) > -1
      );
      if (tabs !== undefined) {
        tabs = Object.assign([], tabs);
        tabs.push({ name: "back", value: "Start" });
        return tabs;
      }
      return [];
    },
  },
  watch: {
    tab() {
      this.currentTabComponent = defineAsyncComponent(() =>
        import(`../../components/settings/${this.tab}.vue`)
      );
    },
  },
  components: { SwitchButtons },
};
</script>
